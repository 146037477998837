.datePicker {
    position: relative;
    width: 100%;
    background: #f7f7fa;
    border: 0.5px solid #f25cc5;
    box-shadow: 0px 4px 20px rgba(black, 0.1);
    border-radius: 6px;
    padding: 6px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
  
  .text {
    width: 100%;
    text-align: center;
  }