.paper {
  position: fixed;
  z-index: +1031;
  background-color: rgba(black, 0.5);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  box-shadow: 0px 3px 5px -1px rgba(black, 0.2),
    0px 5px 8px 0px rgba(black, 0.14), 0px 1px 14px 0px rgba(black, 0.12);
  margin: 0 auto;
  background-color: #fefefe;
  width: 67%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 8px 16px;
}

.closeBtnWrapper {
  position: absolute;
  right: -36px;
  top: -32px;

  .closeBtn {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: #f25cc5;
    font-weight: bold;
    cursor: pointer;
  }

  .closeBtn:focus {
    border: 0;
  }
}

.header,
.footer {
  display: flex;
  padding: 8px 0;
}

.footer {
  padding-top: 20px;
}

.header {
  position: relative;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
}

.noHeader {
  border: 0;
  padding: 0;
}

.noFooter {
  padding: 0;
}

.title {
  flex: 1 1;

  h4 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
  }
}

.content {
  padding: 16px 0;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  background-color: white;
}

@media only screen and (max-width: 575px) {
  .container {
    width: 90%;
  }

  .closeBtnWrapper {
    right: -32px;
    top: -32px;
  }

  .title {
    h4 {
      font-size: 16px;
    }
  }
}
